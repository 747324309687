import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Box from '@base/Box'

import Logo from '@components/flow/Logo'
import Line from '@components/flow/Line'
import GoBack from '@components/flow/GoBack'
import CloseDialog from '@components/flow/CloseDialog'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 50px)',
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 50px)',
  },
}))

type Props = {
  children: any
  logoUp?: boolean
  goBackProps: {
    label: string
    mobileLabel: string
    onClick?: () => void
  }
  closeDialogProps: {
    title: string
    text: string
    quitText: string
    closeText: string
    handleQuit: () => void
  }
  showLine?: boolean
}

const Wrapper = ({ children, logoUp, goBackProps, closeDialogProps, showLine }: Props) => {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const openDialog = () => {
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  return (
    <Box maxWidth='100%' overflow='hidden' width='100%' position='relative'>
      {/* Stretch header and content to at least full viewport height */}
      <Box className={classes.boxWrapper}>
        <GoBack
          label={goBackProps.label}
          mobileLabel={goBackProps.mobileLabel}
          onClick={goBackProps.onClick ? goBackProps.onClick : openDialog}
        />
        <Logo logoUp={logoUp} />
        {showLine && <Line />}
        <CloseDialog
          open={dialogOpen}
          handleClose={closeDialog}
          title={closeDialogProps.title}
          text={closeDialogProps.text}
          quitText={closeDialogProps.quitText}
          closeText={closeDialogProps.closeText}
          handleQuit={closeDialogProps.handleQuit}
        />

        <Box flexGrow={1}>
          <Grid container alignItems='stretch' justifyContent='flex-start' className={classes.root}>
            {children}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default Wrapper
