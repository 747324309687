import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import close from '@static/icons/close.svg'
import { useTranslation } from '@src/i18n'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    padding: '56px 76px',
    [breakpoints.down('xs')]: {
      padding: '24px 36px',
    },
    textAlign: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: '2.5rem',
    [breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  text: {
    color: '#626261',
    fontSize: '1rem',
    fontWeight: 600,
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonBox: {
    width: 'calc(50% - 7px)',
  },
  image: {
    height: '50px',
    width: '50px',
    padding: '15px',
    position: 'absolute',
    right: '0',
    top: '0',
    cursor: 'pointer',
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export type Props = {
  open: boolean
  title: string
  text: string
  quitText: string
  closeText: string
  handleClose: () => void
  handleQuit: () => void
}

const CloseDialog = ({ open, title, text, quitText, closeText, handleClose, handleQuit }: Props) => {
  const classes = useStyles({})
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={text}
      PaperProps={{
        style: { borderRadius: '8px' },
      }}
    >
      <img key='icon' src={close} alt={t('common.close')} className={classes.image} onClick={handleClose} />
      <Box className={classes.root}>
        <Box fontSize={{ xs: '1.5rem', md: '2.5rem' }}>
          <Typography component='h1' variant='h3' className={classes.title}>
            {title}
          </Typography>
        </Box>

        <Box mb={5} mt={3}>
          <Typography className={classes.text}>{text}</Typography>
        </Box>

        <Box className={classes.buttonsBox}>
          <Box className={classes.buttonBox}>
            <Button type='submit' variant='outlined' size='large' color='primary' fullWidth onClick={handleQuit}>
              {quitText}
            </Button>
          </Box>
          <Box className={classes.buttonBox}>
            <Button type='submit' variant='contained' size='large' color='primary' fullWidth onClick={handleClose}>
              {closeText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default CloseDialog
