import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import links from '@api/links'
import Box from '@base/Box'
import BaseLogo from '@base/Logo'
import Link from '@base/Link'

const useStyles = makeStyles<Theme, Props>(({ breakpoints }) => ({
  box: {
    position: 'absolute',
    right: '2em',
    top: '2em',
    bottom: 'unset',
    [breakpoints.up('md')]: {
      right: '3em',
      top: props => (props.logoUp ? '2em' : 'unset'),
      bottom: props => (props.logoUp ? 'unset' : '2em'),
    },
  },
}))

type Props = {
  logoUp?: boolean
}

export default function Logo({ logoUp }: Props) {
  const classes = useStyles({ logoUp })

  return (
    <Box className={classes.box}>
      <Link href={links.home}>
        <BaseLogo />
      </Link>
    </Box>
  )
}
