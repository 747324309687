import React from 'react'
import Typography from '@base/Typography'
import Box from '@base/Box'
import goBack from '@static/icons/arrow-back.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import theme from '@src/lib/theme'
import { useTranslation } from '@src/i18n'

export const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
  label: {
    marginLeft: '1em',
    fontWeight: 600,
    fontSize: '14px',
    color: '#91959E',
  },
  image: {
    height: '20px',
    width: '20px',
  },
}))

export type Props = {
  label: string
  mobileLabel: string
  onClick: () => void
}

export default function GoBack({ label, mobileLabel, onClick }: Props) {
  const classes = useStyles({})
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  return (
    <Box
      position='absolute'
      top={{ xs: '3em', md: '3em' }}
      left={{ xs: '3em', md: '3em' }}
      onClick={onClick}
      className={classes.root}
    >
      <Box display='flex' alignItems='center' justifyContent='center'>
        <img key='icon' src={goBack} alt={t('back.back')} className={classes.image} />
        <Typography fontWeight={500} className={classes.label}>
          {isMobile ? mobileLabel : label}
        </Typography>
      </Box>
    </Box>
  )
}
