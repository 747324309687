import BoxContainer from '@base/BoxContainer'
import Typography from '@base/Typography'
import Box from '@base/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Title from './Title'

const useStyles = makeStyles(({ breakpoints }) => ({
  boxContainer: {
    paddingTop: '150px',
    paddingBottom: '150px',
    alignItems: 'inherit',
    [breakpoints.up('md')]: {
      paddingTop: '80px',
      paddingBottom: '80px',
      alignItems: 'center',
    },
  },
  subTitle: {
    fontSize: '14px',
    color: '#626261',
    fontWeight: 600,
    marginTop: '16px',
  },
}))

type Props = {
  title: any
  subtitle?: string
  grid?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  children: any
}

export default function Section({ title, subtitle, grid, children }: Props) {
  const classes = useStyles({})
  return (
    <Grid container item xs={12} md={grid ? grid : 8} justifyContent='flex-start'>
      <BoxContainer
        component='section'
        pt={{ xs: 4, md: 4 }}
        pb={{ xs: 6, md: 0 }}
        ml={{ xs: 0, md: 12 }}
        textAlign='left'
        innerProps={{
          maxWidth: 600,
          flexDirection: 'column',
        }}
        justifyContent='center'
        className={classes.boxContainer}
      >
        <Title>{title}</Title>
        {subtitle && (
          <Box maxWidth={350}>
            <Typography className={classes.subTitle}>{subtitle}</Typography>
          </Box>
        )}
        {children}
      </BoxContainer>
    </Grid>
  )
}
