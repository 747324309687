import React from 'react'
import Typography from '@base/Typography'
import Box from '@base/Box'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  textAlign?: 'left' | 'center'
  'data-testid'?: string
  children: string
}

type StylesProps = {
  textAlign: 'left' | 'center'
}

const useStyles = makeStyles<Theme, StylesProps>(({ breakpoints }) => ({
  title: {
    fontWeight: 600,
    fontSize: '2rem',
    width: '100%',
    maxWidth: '350px',
    [breakpoints.up('md')]: {
      fontSize: '2.5rem',
      maxWidth: '100%',
    },
    textAlign: props => props.textAlign,
  },
}))

const Title = ({ textAlign = 'left', children, 'data-testid': testId }: Props) => {
  const classes = useStyles({ textAlign })
  return (
    <Box mb={1} fontSize={{ xs: '1.5rem', md: '2.5rem' }} display='flex' justifyContent='center' data-testid={testId}>
      <Typography component='h1' variant='h3' className={classes.title}>
        {children}
      </Typography>
    </Box>
  )
}

export default Title
